/* global OfferKit */
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'
import { ReactComponent as Logo } from '../images/default/smith-harris-logo.svg'
import { ReactComponent as Address } from '../images/default/11-abigail.svg'

const Background = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  background: url(https://images.adsttc.com/media/images/5ece/90c3/b357/65c6/7300/01eb/large_jpg/AGP1324_5932-Pano.jpg?1590595667);
  background-size: cover;
  background-position: center;
  margin: 0;
`

const StyledLogo = styled(Logo)`
  position: absolute;
  left: 32px;
  top: 32px;
  width: 96px;
`

const StyledAddress = styled(Address)`
  position: relative;
  display: block;
  width: 320px;
  max-width: 80vw;
  margin: 0 auto;
  padding-top: 128px;
`

const GlobalStyles = css`
  .button-override {
    background: black !important;
  }
`

const Home = () => {
  // eslint-disable-next-line no-undef
  useEffect(() => {
    OfferKit.exec('mount', { listingId: 'lst_offerkit-test-11-abigail' })
    return () => {
      OfferKit.exec('unmount')
    }
  }, [])

  return (
    <Background>
      <Global styles={GlobalStyles} />
      <StyledLogo />
      <StyledAddress />
    </Background>
  )
}

export default Home
