/* global OfferKit */
/** @jsx jsx */
import { jsx, css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect } from 'react'

const PIETA_RED = 'rgb(219, 57, 57)'

const Background = styled.div`
  display: block;
  margin: 0;
  min-height: 100vh;
  width: 100%;
`

const Header = styled.div`
  width: 100%;
  height: 110px;
  background-color: white;
  padding: 0 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`

const GlobalStyles = css`
  html,
  body {
    font-family: 'Nunito Sans', sans-serif;
    background-color: #edefea;
  }
`

const StyledLogo = styled.img`
  width: 259px;
`

const Container = styled.div`
  width: 100%;
  @media (min-width: 1024px) {
    width: 76%;
  }
  max-width: 1920px;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-gap: 32px;
  padding: 32px;
  box-sizing: border-box;
`

const CarouselImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: cover;
  display: block;
`

const Pieta = () => {
  useEffect(() => {
    OfferKit.exec('mount', {
      listingId: 'lst_offerkit-test-28-8-koorala',
      selector: '#make-an-offer',
    })
    return () => {
      OfferKit.exec('unmount')
    }
  }, [])

  return (
    <Background>
      <Global styles={GlobalStyles} />
      <Header>
        <StyledLogo src="https://www.pieta.com.au/wp-content/uploads/2020/08/Pieta-logo-new-1_2x.png" />
        <div />
      </Header>
      <CarouselImage
        src="https://img.agentaccount.com/d6b9b8b2b23af2766cd6855444005052003a328d"
        width="1116"
        height="774"
        alt="house"
        style={{ marginBottom: '32px' }}
      />
      <Container>
        <div>
          <div css={{ backgroundColor: 'white', padding: 16, minHeight: 400 }}>
            <div
              css={{
                height: 130,
                width: 130,
                borderRadius: 99999,
                backgroundColor: 'rgba(102, 102, 102, 0.25)',
                margin: '0 auto',
              }}
            />
          </div>
        </div>
        <div>
          <h1
            css={{
              textTransform: 'uppercase',
              fontSize: 34,
              fontWeight: 800,
              color: PIETA_RED,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>Brand New Townhouse</span>
            <span css={{ fontWeight: 'normal' }}>$549,950</span>
          </h1>
          <div css={{ margin: '32px 16px' }}>
            <h2
              css={{
                fontWeight: 'bolder',
                color: 'rgb(102, 102, 102)',
                fontSize: 16,
                marginBottom: 16,
                lineHeight: 1.5,
              }}
            >
              For Sale, Residential
              <br />
              1/145 Oxford Street, Cambridge Park NSW 2747
            </h2>

            <button
              id="make-an-offer"
              css={{
                backgroundColor: PIETA_RED,
                color: 'white',
                font: 'inherit',
                height: 56,
                padding: '0 32px',
                fontWeight: 'bold',
                overflow: 'hidden',
                border: `1px solid ${PIETA_RED}`,
                transition: 'all 250ms ease',
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: 'white',
                  color: PIETA_RED,
                },
              }}
            >
              Make an offer
            </button>
          </div>

          <div
            css={{
              backgroundColor: 'white',
              padding: 16,
              fontSize: '15px',
              color: 'rgb(102, 102, 102)',
            }}
          >
            <h3 css={{ marginTop: 0, color: 'black' }}>Description</h3>
            <p>
              Perfect for the astute first home buyer this modern brand new
              townhouse is finished to the highest standard. With an abundance
              of natural light and a functional floorplan this compact townhouse
              has it all.
            </p>
            <p>
              'The Peninsula' offers beautifully landscaped gardens and grounds
              and wonderful amenities which include a lovely indoor/outdoor
              heated pool, sauna and spa as well as a meeting room, games room
              and a well-equipped gym.
            </p>
          </div>
        </div>
      </Container>
    </Background>
  )
}

export default Pieta
