import React from 'react'
import { css, Global } from '@emotion/react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import DiJones from './pages/DiJones'
import Harcourts from './pages/Harcourts'
import Home from './pages/Home'
import LJHSeaforth from './pages/LJHSeaforth'
import NorthSouth from './pages/NorthSouth'
import Pieta from './pages/Pieta'
import Standen from './pages/Standen'

const fonts = css`
  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&family=Cardo:wght@700&family=Raleway&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700;800&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Martel:wght@400;600&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Martel+Sans:wght@200&display=swap');
`

const App = () => {
  return (
    <BrowserRouter>
      <Global styles={fonts} />
      <Switch>
        <Route path="/" exact>
          {() => <Home />}
        </Route>
        <Route path="/harcourts" exact>
          {() => <Harcourts />}
        </Route>
        <Route path="/northsouth" exact>
          {() => <NorthSouth />}
        </Route>
        <Route path="/dijones" exact>
          {() => <DiJones />}
        </Route>
        <Route path="/ljhseaforth" exact>
          {() => <LJHSeaforth />}
        </Route>
        <Route path="/standen" exact>
          {() => <Standen />}
        </Route>
        <Route path="/pieta" exact>
          {() => <Pieta />}
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default App
