/* global OfferKit */
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'
import { ReactComponent as Logo } from '../images/harcourts/logo.svg'
import { ReactComponent as Address } from '../images/harcourts/address.svg'

const Background = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  background: url('https://images.adsttc.com/media/images/5ece/90c3/b357/65c6/7300/01eb/large_jpg/AGP1324_5932-Pano.jpg?1590595667');
  background-size: cover;
  background-position: center;
  margin: 0;
`

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 92px;
  background: rgba(255, 255, 255, 1);
  backdrop-filter: blur(20px);
`

const GlobalStyles = css`
  html,
  body {
    font-family: 'Source Sans Pro', sans-serif;
  }

  .button-override {
    background: #00afef !important;
  }
`

const StyledLogo = styled(Logo)`
  position: absolute;
  left: 32px;
  top: 24px;
  width: 128px;
`

const StyledAddress = styled(Address)`
  position: relative;
  display: block;
  width: 400px;
  max-width: 80vw;
  margin: 0 auto;
  padding-top: 160px;
`

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 108px;
  background: rgba(26, 53, 83, 1);
  backdrop-filter: blur(20px);
`

const Home = () => {
  useEffect(() => {
    OfferKit.exec('mount', { listingId: 'lst_offerkit-test-11-abigail' })
    return () => {
      OfferKit.exec('unmount')
    }
  }, [])

  return (
    <Background>
      <Global styles={GlobalStyles} />
      <Header>
        <StyledLogo />
      </Header>
      <StyledAddress />
      <Footer />
    </Background>
  )
}

export default Home
